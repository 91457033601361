import Analytics from './analytics'
import getCurrentIp from './../api/getCurrentIp'

const registerUserProperties = ({ properties, analytics }) => {
  analytics.setUserId(properties.userId)
  analytics.setUserProperties(properties)
  analytics.logEvent('WEB_SUPER_ADMIN_LOGGED_IN')
}

const registerUserPropertiesToAnalytics = () => {
  const user = JSON.parse(JSON.parse(window.localStorage.getItem('persist:root'))?.User)?.user
  if (!user) return true

  const userProperties = {
    userId: user.id,
    userType: 'ADMIN',
    environment: process.env.REACT_APP_ENVIRONMENT || 'local'
  }

  return getCurrentIp()
    .then(ip => {
      userProperties['ipAddress'] = ip
      console.log(userProperties)
      registerUserProperties({ properties: userProperties, analytics: Analytics })
    })
    .catch(() => registerUserProperties({ properties: userProperties, analytics: Analytics }))
}

export default registerUserPropertiesToAnalytics
