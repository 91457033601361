import Api from './index'

const getCurrentIp = () => Api.graphql({
  query: `
    query {
      getClientIp
    }
  `,
}).then(res => Promise.resolve(res?.data?.data?.getClientIp)).catch(err => Promise.reject(err))

export default getCurrentIp
